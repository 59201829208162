import React, {Fragment} from 'react';
import '../styles.css';
import { observer } from 'mobx-react-lite'
import Tooltip from '../Tooltip'
import { ReactComponent as AttachmentIcon } from '../../ui/icons/svg/attachment.svg'
import { ReactComponent as ConversationIcon } from '../../ui/icons/svg/conversation.svg'
import IconButton from '../../ui/buttons/IconButton.js';


const base_cls = "field-wraper bg-transparent focus:outline-none rounded-lg py-2 px-4 block w-full appearance-none leading-normal h-full flex flex-row"
const cls_modifiers = {
    "error": " border-red-600 border-2",
    "outlined": " focus-within:border-primary focus-within:border-2 border border-gray-400",
    "readonly": " border border-gray-300 bg-gray-200",
    "outlined_no_focus": " border border-gray-400",

}

const shrinkLabel = {
    transform:'translateY(-1.5em)',
    display:'inline-block'
}

const fixedLabels = ['boolean', 'one2many', 'html']
const no_focus_fields = ['html']

const reducedPadding = ['selection']


const FieldWrapper = observer(function FieldWrapper(props) {
// function FieldWrapper(props) {
    const {field, value, state_attrs, record, customInputStyle, hideLabel, show_resources } = props    
    const error = record._errors[field.name]
    
    const cls = () => {


        if (error) {

            return base_cls.concat(cls_modifiers["error"])

        }
        if (state_attrs.readonly) {
            return base_cls.concat(cls_modifiers['readonly'])
        }

        
        if(no_focus_fields.includes(field.type)){
            return base_cls.concat(cls_modifiers['outlined_no_focus'])
        }
        return base_cls.concat(cls_modifiers[props.variant])

    }

      const cls_filter = () => {
        if (!field.filter.is_default && customInputStyle) {
          return "hidden invisible";
        }
        if (customInputStyle) {
          return "mt-1.5 ml-2 z-50";
        }
      };

    const style = {
        padding: reducedPadding.includes(props.type) ? "0.13rem": props.fieldWrapperStyle,
        

    }




    return (
        <>

            <div style={{...props.style, ...props.fieldWrapperStyle, ...style}} className={cls()} >
                {
                !hideLabel ? 
                    <div style={value || fixedLabels.includes(field.type) || state_attrs.readonly ? shrinkLabel:{}} className={`field-label rounded text-xs bg-white font-medium text-primary px-2 absolute ${cls_filter()}`}>

                    <span className="flex flex-row">
                        {field.description}
                        {show_resources ?
                             <>
                                {
                                field.get_attachments_count(record) ?
                                    <div className="w-3 h-3 ml-2">
                                        <IconButton 
                                            size='sm'
                                            onClick={(e) => { field.open_attachments(record) }} 
                                            color="transparent" 
                                            title="Adjuntos">
                                                <AttachmentIcon className="fill-current text-xxxs cursor-pointer"/> 
                                        </IconButton>
                                        
                                    </div>
                                    :
                                    ""
                                }
                                {
                                field.get_notes_count(record) ?
                                    <div className="w-3 h-3 ml-2">
                                        <IconButton 
                                            size='sm'
                                            onClick={(e) => { field.open_notes(record) }} 
                                            color="transparent" 
                                            title="Notas">
                                                <ConversationIcon className="fill-current text-xxxs cursor-pointer"/> 
                                        </IconButton>
                                        
                                    </div>
                                    :
                                    ""
                                
                                }

                                
                                
                             </>
                            
                            
                            :
                            ""
                        }
                    </span>
                        
                        
                    </div>
                    :
                    ""

                }
                
                <div className='flex-grow w-full h-full' style={field.style || {}}>
                    {props.children}
                </div>
                
               {
               (props.show_tooltip && field.tooltip) ?
                    <Tooltip
                    content={field.tooltip.content}
                    template={field.tooltip.template}
                    />
                    :
                    ""
               
               }
               
            </div>
           
          
        </>


    )

});

export default FieldWrapper;