import React from 'react';
import { AppContextProvider } from './model/context/AppContextProvider'
import { ConnectionProvider } from './connection/ConnectionProvider'
import { ThemeContextProvider } from './theme/ThemeContextProvider'
import { NotificationProvider } from './notifications/NotificationProvider'
import { ContextMenuProvider } from './model/context/ContextMenuProvider';

export const AppRoot = ({ children, use_default_theme, connection_info, load_db_adapter, widgets_registry }) => {
    return (
        <NotificationProvider>
            <ContextMenuProvider>
                <ConnectionProvider connection_info={connection_info} load_db_adapter={load_db_adapter}>
                    <AppContextProvider widgets_registry={widgets_registry}>
                        <ThemeContextProvider use_default_theme={use_default_theme}>
                            {children}
                        </ThemeContextProvider>
                    </AppContextProvider>
                </ConnectionProvider>
            </ContextMenuProvider>
        </NotificationProvider>
    )
}

