import React, { useState, Fragment } from 'react';
import IconButton from '../ui/buttons/IconButton.js';
import { ReactComponent as ReportIcon } from '../ui/icons/svg/report.svg';
import { ReactComponent as LinkIcon } from '../ui/icons/svg/link.svg';
import { ReactComponent as ActionIcon } from '../ui/icons/svg/action.svg';
import { ReactComponent as SearchIcon } from '../ui/icons/svg/search.svg';
import { ReactComponent as ViewIcon } from '../ui/icons/svg/view-show.svg';
import { ReactComponent as Dots } from '../ui/icons/svg/dots-horizontal-triple.svg';
import { ReactComponent as MinusIcon } from '../ui/icons/svg/minus-solid.svg';
import { ReactComponent as ChevronRight } from '../ui/icons/svg/cheveron-right.svg';
import { ReactComponent as ChevronLeft } from '../ui/icons/svg/cheveron-left.svg';
import { ReactComponent as EditPencil } from '../ui/icons/svg/edit-pencil.svg';
import { ReactComponent as AddSolid } from '../ui/icons/svg/add-solid.svg';
import { observer } from 'mobx-react-lite'
import Select from 'react-select';
import DropDownMenu from '../ui/DropDownMenu.js';
import FormButton from '../ui/buttons/FormButton.js';
import ViewEditorDropdown from '../editor/ViewEditorDropdown'
import FilterBar from '../ui/FilterBar.js';
import Badge from '../ui/Badge';
import { FiltersActive } from '../ui/FiltersActive.js';
import clsx from 'clsx';
import ViewEditionToolbar from './ViewEditionToolbar.js'
import Tooltip from '../ui/Tooltip.js';

const icon_cls = "fill-current text-xl cursor-pointer ";


const customSelectStyles = {
    menu: (provided, state) => ({
        ...provided,
        borderRadius: '0px',
        marginTop: '0px'
    }),

    control: (provided, state) => ({

        ...provided,
        border: '0px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        // padding: '0px',
        minHeight: '0px',


    }),

    indicatorSeparator: (provided, state) => ({

        ...provided,
        display: 'none',




    }),
    dropdownIndicator: (provided, state) => ({

        ...provided,
        // display: 'none',
        cursor: 'pointer',
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: '1.5rem',
        // width: '0px',
        color: 'inherit',
        // ':hover': {

        //     display: 'flex',
        // },



    }),
    input: (provided, state) => ({

        ...provided,
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: '1.5rem',



    }),
    valueContainer: (provided, state) => ({

        ...provided,
        cursor: 'pointer',
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: '1.5rem',




    }),
    singleValue: (provided, state) => ({

        ...provided,
        cursor: 'pointer',
        padding: 0,
        maxHeight: '1.5rem',
        fontSize: 'inherit',
        fontWeight: 'inherit',

        color: 'inherit',
        fontFamily: 'inherit',




    }),
    menu: (provided, state) => ({

        ...provided,
        cursor: 'pointer',
        fontSize: '0.75rem',
        fontWeight: '500',
        color: 'rgba(113, 128, 150,1)',
        fontFamily: 'inherit',

    }),
    option: (provided, state) => ({

        ...provided,
        cursor: 'pointer',


    }),

    menuPortal: (provided, state) => ({
        ...provided,
        zIndex: 9999
    }),
    multiValue: (provided, state) => ({
        ...provided,
        border: "1px solid #CBD5E0",
        backgroundColor: "white",
        borderRadius: "5rem",
        paddingLeft: ".25rem",
        paddingRight: ".25rem",
        marginBottom: ".45rem",
      }),
    
      multiValueLabel: (provided, state) => ({
        ...provided,
        color: "rgba(113, 128, 150,1)",
        fontWeight: "500",
      }),
    
      multiValueRemove: (provided, state) => ({
        ...provided,
        ":hover": {},
      }),



}

const NewRecordButton = observer(function NewRecordButton(props) {
    const { screen } = props
    const readonly = screen.readonly
    console.log("SCREEN REAONLY ON NEW RECORD BUTTON")
    console.log(readonly)
    const add_record_button = screen.add_record_button
    if (readonly || !add_record_button) {
        return (<></>)
    }

    return (
        <>
            {
                add_record_button.text ?
                    <div className="py-1 mr-2 flex items-center" >
                        <FormButton
                            variant="list"
                            state_attrs={{}}
                            className=" truncate text-xs  py-1 px-2"
                            color={add_record_button.color || 'primary'}
                            onClick={(e) => { add_record_button.execute() }}>
                            {add_record_button.text}
                        </FormButton>
                    </div>
                    :
                    <IconButton onClick={(e) => { screen.add_record_button.execute() }} color="transparent" title="Agregar">
                        <AddSolid className={icon_cls} />
                    </IconButton>

            }
        </>


    )

})

const AddNewRecordButton = observer(function AddNewRecordButton(props) {
    const { screen } = props
    const default_save_action = screen.default_save_action
    if (screen.readonly || (default_save_action && default_save_action.invisible)) {
        return (<React.Fragment></React.Fragment>)
    }

    async function createRow(rowIndex, columnIndex) {
        if (!rowIndex || rowIndex === props.rowCount) {
            rowIndex = props.rowCount + 1
            columnIndex = 1
        }

        let record = screen.data.addRecord({ values: {}, index: rowIndex })
    }

    return (
        <IconButton title="Agregar" color="transparent" onClick={() => createRow()}>
            <AddSolid className={icon_cls} />
        </IconButton>
    )

})

const ActionsBar = observer(function ActionsBar(props) {
    const { screen, parent_ref } = props
    const actions = screen.actions

    // const [buttonActions, setButtonActions] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [openMenu, setOpenMenu] = React.useState(false);
    const [menuOptions, setMenuOptions] = React.useState([]);
    const selected_qty = screen.selected_records ? screen.selected_records.length : 0
    const readonly = screen.readonly

    const link_actions = screen.link_actions
    const report_actions = screen.report_actions
    const action_actions = screen.action_actions
    const view_group_actions = screen.view_group_actions
    const general_actions = screen.general_actions
    const delete_action = screen.delete_action
    const button_actions = screen.button_actions
    const editor = screen.editor
    const editor_actions = editor.edition_options
    const design_mode = editor.design_mode
    const filters = screen.filters



    function executeAction(e, id) {

        // props.executeAction(id, { rowIndex: Object.keys(props.selected) })
        const action = actions.find(act => act.id === id)
        action.execute()


    }

    function onClickButton(e, type) {

        setAnchorEl(e.currentTarget);
        let menu_actions = []
        switch (type) {
            case 'link':
                menu_actions = link_actions
                break;
            case 'general_menu':
                menu_actions = general_actions
                break;
            case 'report':
                menu_actions = report_actions
                break;

            default:
                menu_actions = []
                break;
        }



        setMenuOptions(menu_actions.map(function (act) {
            return { label: act.rec_name, id: act.id, onClick: executeAction }
        }))


        setOpenMenu(true)
    }

    function closeMenu(e) {
        setAnchorEl(false)
        setOpenMenu(false)
    }



    const isVisible = (action) => {
        if (action.invisible) { return false }
        return action.visible_on_select ? selected_qty > 0 : true

    }

    const isVisibleOnSelect = (action) => {
        return action.visible_on_select ? selected_qty > 0 : true

    }






    return (
        <div className="flex justify-center items-center space-x-1">
            {button_actions.map((action) => (
                <React.Fragment key={action.id}>
                    {isVisibleOnSelect(action) &&
                        <div className="flex items-center">
                            <FormButton
                                variant="list"
                                state_attrs={{}}
                                className=" truncate text-xs py-1 px-2 mr-2"
                                color={action.button_color || 'transparent'}
                                onClick={(e) => { action.execute() }}>
                                {action.rec_name}
                            </FormButton>
                        </div>
                    }



                </React.Fragment>


            ))}


            {delete_action !== false &&
                <>
                    {(!readonly && isVisible(delete_action)) &&
                        <IconButton onClick={(e) => { executeAction(e, delete_action.id) }} color="warning" title="Eliminar">
                            <MinusIcon className={icon_cls} />
                        </IconButton>
                    }

                </>

            }

            {props.isSpreadsheet &&
                <AddNewRecordButton rowCount={props.rowCount} screen={screen} />
            }

            <>
                {screen.filterable ?

                    // <IconButton color="transparent" className="text-primary">
                    <FilterBar className="fill-current  text-xl cursor-pointer w-4 h-2" screen={screen}
                        parent_ref={parent_ref}
                    />
                    // </IconButton>    
                    :
                    ""
                }
            </>

            {screen.add_record_button !== false &&
                // <IconButton onClick={(e) => { screen.add_record_button.execute() }} color="transparent">
                //     <AddSolid style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                // </IconButton>
                <NewRecordButton screen={screen} />

            }


            {props.search &&
                <IconButton onClick={props.toogleSearch} color="transparent">
                    <SearchIcon className={icon_cls} />

                </IconButton>
            }

            {view_group_actions.length !== 0 &&

                <IconButton onClick={(e) => { onClickButton(e, 'view_group') }} color="transparent">
                    <ViewIcon className={icon_cls} />
                </IconButton>

            }

            {link_actions.length !== 0 &&
                <IconButton title="Ir a:" onClick={(e) => { onClickButton(e, 'link') }} color="transparent" shape="square">
                    <LinkIcon className={icon_cls} />
                </IconButton>
            }

            {report_actions.length !== 0 &&
                <IconButton title="Reportes" onClick={(e) => { onClickButton(e, 'report') }} color="transparent">
                    <ReportIcon className={icon_cls} />
                </IconButton>
            }

            {action_actions.length !== 0 &&

                <IconButton onClick={(e) => { onClickButton(e, 'action') }} color="transparent">
                    <ActionIcon className={icon_cls} />
                </IconButton>

            }
            {general_actions.length !== 0 &&
                <IconButton title="Más Acciones" onClick={(e) => { onClickButton(e, 'general_menu') }} color="transparent">
                    <Dots className={icon_cls} />
                </IconButton>

            }
            {props.extra_action_menu ?
                <>{props.extra_action_menu}</>
                :
                ""
            }

            {editor_actions && editor_actions.length !== 0 &&
                <ViewEditorDropdown screen={screen} />


            }
            {design_mode &&
                <ViewEditionToolbar screen={screen} editor={editor} />


            }

            <DropDownMenu
                id="actions-menu"
                anchor={anchorEl}
                options={menuOptions}
                open={Boolean(openMenu)}
                onClose={closeMenu}
            />





        </div>
    )
});


const SpreadsheetHeader = observer(function SpreadsheetHeader(props) {

    const { screen } = props;
    const { limit, pagination_enabled, page_size_options, page_qty, current_page } = screen.data

    const val = page_size_options.find(o => o.value == limit)

    function onChangeLimit(value) {
        screen.set_limit(parseInt(value.value))
    }


    return (
        <div className="flex h-full w-30">
            {pagination_enabled &&

                <div className="block border-l w-full text-gray-600 border-r border-gray-300 text-sm font-medium text-center">
                    <small>
                        <span className='leading-4'>Página <strong>{current_page}</strong> de{" "}<strong>{page_qty}</strong></span>
                        <div className="hover:text-primary -mt-1.5">
                            <Select
                                isSearchable={false}
                                value={val}
                                styles={customSelectStyles}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                // placeholder={field.description}
                                onChange={(value) => onChangeLimit(value)}
                                options={page_size_options}
                            />
                        </div>

                    </small>


                </div>







            }

        </div>



    )

});

const RecordCounter = observer(function RecordCounter(props) {

    const { screen, className, style } = props;
    const count = screen.data.count
    /* const cls = "items-center text-gray-600 text-base font-normal ".concat( className)*/

    return (
        /* <div className={cls} style={style}>
            <div className='w-30'>{count} resultados</div>
        </div>*/


        <Badge visibility={true} title={`${count} resultados`} color="primary"></Badge>

    )

});

const PaginationControl = observer(function PaginationControl(props) {

    const { screen } = props;
    const { pagination_enabled } = screen.data


    return (
        <div className='flex ml-3 mr-3'>
            {pagination_enabled &&
                <div className='flex items-center'>
                    <div>
                        <IconButton
                            disabled={
                                (screen.data.page_qty !== 1 &&
                                    screen.data.current_page === 1) ||
                                screen.data.page_qty === 1
                            }
                            title="Pág. Anterior"
                            onClick={(e) => {
                                screen.data.prevPage();
                            }}
                            color="transparent"
                            shape="circle"
                            size="sm"
                            className="transition duration-300 bg-primary active:bg-primary">
                            <ChevronLeft className="transition duration-300 fill-white hover:fill-current active:fill-white text-xl cursor-pointer" />
                        </IconButton>

                    </div>

                    <div className="border-l border-r border-gray-400 mx-2 h-full">
                        {['list', 'spreadsheet'].includes(screen.type) &&

                            <SpreadsheetHeader screen={screen} />
                        }


                    </div>
                    <div>
                        <IconButton
                            disabled={
                                screen.data.current_page === screen.data.page_qty ||
                                screen.data.page_qty === 1 ||
                                screen.data.page_qty === 0
                            }
                            title="Pág. Siguiente"
                            onClick={(e) => {
                                screen.data.nextPage()
                            }}
                            color="transparent"
                            shape="circle"
                            size="sm"
                            className="transition duration-300 ease-in-out bg-primary active:bg-primary">
                            <ChevronRight className="transition duration-300 fill-white hover:fill-current active:fill-white text-xl cursor-pointer" />
                        </IconButton>

                    </div>
                </div>
            }

        </div>






    )

});



const ViewHeader = observer(function ViewHeader(props) {

    const [search, setSearch] = useState(false)
    const { screen, hide_toolbar, title_classname, container_classname, tooltip } = props;
    const { pagination_enabled, show_records_count } = screen.data
    const selected_qty = screen.selected_records ? screen.selected_records.length : 0
    const { title } = screen
    const base_container_cls = "w-full flex justify-between mb-0"
    const container_cls = container_classname ? clsx(container_classname, base_container_cls) : base_container_cls
    const isSmartOption = screen.smart_sort_options && screen.smart_sort_options.length > 0 ? true : false;
    const enableGroupBy = screen.group_by_fields && screen.group_by_fields.length ? true:false


    function toogleSearch() {

        setSearch(!search)
    }

    const changueSmartOrder = (e) => {
        let fieldName = e.field.rec_name
        let valueName = e.value
        screen.set_order(fieldName, valueName)
    }

    const changeGroupBy = (value) => {
        console.log("Value on change group by")
        console.log(value)
        if(!value){
            value = []
        }
        else{
            value = value.map(function(value){return value.name})
        }
        screen.set_group_by(value)
    }

    const groupByValues = () => {
        console.log("Base screen group by =?")
        console.log(screen.group_by)
        const values = screen.group_by.map(function(val){
            return screen.get_field_by_name(val)
            // console.log("VAL ON GROUP BY MAP")
            // console.log(val)
            // return {
            //     'label':val,
            //     'value':val
            // }         
        })
        console.log("GROUP BY VALUES GETTED")
        console.log(values)
        return values
    }

    

    const ref = React.createRef();

    return (
        <div className="viewheader mb-0">
            <div className={container_cls + " " + "border-none mt-3 mb-3.5"}>
                <div ref={ref} className="w-full flex items-center justify-between mx-3 appearance-none leading-normal ">

                    <div className="col-span-4 flex h-full items-center space-x-2 overflow-hidden mr-3">
                        
                        <div style={{ maxWidth: (window.innerWidth * 25) / 100 }} className="max-w-md text-xl text-left text-gray-600 font-normal tracking-tight overflow-ellipsis overflow-hidden whitespace-nowrap mr-1.5">{title}</div>
                        {tooltip &&
                         <Tooltip
                            content={tooltip.content}
                            template={tooltip.template}
                         />
                        }

                        {show_records_count && <RecordCounter screen={screen} />}

                        <Badge
                            visibility={selected_qty > 0 && !hide_toolbar}
                            title={
                                selected_qty === 1
                                    ? selected_qty + " registro seleccionado"
                                    : selected_qty + " registros seleccionados"
                            }
                            color="primary"
                        />
                    </div>

                    {/* <div className="col-start-8 col-span-1">
                    {['list', 'spreadsheet'].includes(screen.type) &&

                        <SpreadsheetHeader screen={screen} />
                    }
                </div> */}
                    {!hide_toolbar &&
                        <div className='flex ml-3'>

                            <ActionsBar
                                parent_ref={ref}
                                toogleSearch={toogleSearch}
                                search={props.filter_bar ? true : false}
                                screen={screen}
                                isSpreadsheet={props.isSpreadsheet}
                                extra_action_menu={props.extra_action_menu}
                                rowCount={props.rowCount}

                            />

                        </div>
                    }


                    {/* <div className="flex mb-2 flex-row justify-between items-center  rounded-lg px-2  w-full appearance-none leading-normal">
                {search ?
                    <>{props.filter_bar()}</> : ""
                }
            </div> */}

                </div>
                {
                    isSmartOption ?

                        <div className='flex items-center border border-gray-400 rounded-md ml-3 mr-3'>
                            <div className="hover:text-primary w-24 text-gray-600  text-xs font-medium" >
                                <Select
                                    placeholder={'Ordenar'}
                                    options={screen.smart_sort_options}
                                    onChange={(value) => changueSmartOrder(value)}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.name}
                                    styles={customSelectStyles}
                                />
                            </div>
                        </div>
                        : ''
                }
                {
                    enableGroupBy ?

                        <div className='flex items-center border border-gray-400 rounded-md ml-3 mr-3 w-1/2'>
                            <div className="hover:text-primary w-24 text-gray-600  text-xs font-medium w-full" >
                                <Select
                                    placeholder={'Agrupar'}
                                    isMulti={true}
                                    value={groupByValues()}
                                    options={screen.group_by_fields}
                                    onChange={(value) => changeGroupBy(value)}
                                    getOptionLabel={option => option.description}
                                    getOptionValue={option => option.name}
                                    styles={customSelectStyles}
                                />
                            </div>
                        </div>
                        : ''
                }


                {pagination_enabled ?
                    <PaginationControl screen={screen} />
                    :
                    <>
                        {/* {show_records_count &&
                        <RecordCounter screen={screen} className="px-4" style={{ border: '0px' }} />
                    } */}
                    </>
                }


            </div>
            {/* NEEDS REVIEW: All chips(filters) in o2m fields are hidden */}
            {(screen.filterable && !screen.field_instance) ?
                <div className={`filter-chips px-3`}>
                    <FiltersActive filters={screen.filters} />
                </div>
                : ""
            }
        </div>




    )

});
export default ViewHeader;