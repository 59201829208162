import BinaryImagePreview from './BinaryImagePreview'
import ColorPicker from './ColorPicker'
import EmptyWidget from './EmptyWidget'
import MultiLink from './MultiLink.js';
import MultiSelection from './MultiSelection.js';
import {JavascriptEditor, PythonEditor, HTMLEditor, CSSEditor} from './CodeText';

let components = {
    'binary':{
        'image_binary':BinaryImagePreview
    },
    'attachment':{
        'image':BinaryImagePreview,
    },
    'char':{
        'color_picker':ColorPicker
    },
    'multi_selection':{
        'transfer_list':MultiSelection
    },
    'multi_link':{
        'transfer_list':MultiLink
    },
    'text':{
        'code_editor_py':PythonEditor,
        'code_editor_js':JavascriptEditor,
        'code_editor_html':HTMLEditor,
        'code_editor_css':CSSEditor
    }
}

// Add overrides for fields using a widget,EX:
// 'text':{
//     'code_editor_html':{
//         'no_wrap':true
//     }
// }
let definitions = {}

const get = (field_type,name) => {
    if(components[field_type] && components[field_type][name]){
        return components[field_type][name]
    }
    else{
        return false
    }
    
}

const get_definition = (field_type,name) => {
    if(definitions[field_type] && definitions[field_type][name]){
        return definitions[field_type][name]
    }
    else{
        return false
    }
    
}


export const widgets = {
    get,
    get_definition
}