import React from 'react';
import Paper from '../../ui/surfaces/Paper.js'
import { observer } from 'mobx-react-lite'
import LoadingIndicator from '../../common/LoadingIndicator'

const HtmlView = observer(function HtmlView(props) {

    const { screen } = props;
    //const content = screen.content;
    const content = screen ? screen.content && screen.content : props.htmlValue;
    const loading = screen ? screen.processing:false
    const container_ref = React.createRef()

    return (
        <div ref={container_ref}>
            <Paper shadowClass="shadow-none" className={props.fromCell ? "" : "py-2 px-2 rounded"} style={props.containerStyle}>
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
                <LoadingIndicator
                    ref={container_ref}
                    show={loading}
                    fullscreen
                    
                />
            </Paper>
        </div>

    )

});

export default HtmlView;