import React, { useState, useEffect, useContext, Fragment, forwardRef, createRef } from 'react';
import Paper from '../../ui/surfaces/Paper.js'
import Field from '../../ui/fields/Field.js'
import FormDivider from '../../ui/fields/FormDivider.js'
import FormButton from '../../ui/buttons/FormButton.js'
import LoadingIndicator from '../../common/LoadingIndicator'
import { observer } from 'mobx-react-lite'
import ViewHeader from '../ViewHeader'
import clsx from 'clsx';
import { Responsive, WidthProvider } from "react-grid-layout";
import GridHoc from '../../ui/GridHoc.js'




const FormField = observer(forwardRef(function FormField(props, ref) {
    const { field, record, edition_mode } = props;
    // const edition_mode = field.screen.editor ? field.screen.editor.design_mode:false

    return (
        <div
            onContextMenu={function (e) {
                e.preventDefault()
                field.open_context_menu(e.currentTarget, record)
                e.stopPropagation()
            }}
            
            ref={ref}
            {...props}
            key={field.name}
        >
            <Field
                field={field}
                record={record}
                variant="outlined"
                edition_mode={edition_mode}
                show_tooltip
                show_resources
            />
            {props.children}
        </div>
    )
}));



const NewFormView = observer(function NewFormView(props) {
    const container_ref = React.createRef()
    const { screen } = props;
    const fields = screen.visible_fields;
    const record = screen.active_record
    const loading = screen.processing
    const default_columns = screen.default_columns
    const edition_mode = screen.editor ? screen.editor.design_mode:false
    
    return (
        <div ref={container_ref}>
            <Paper className="py-2 px-2 rounded" style={props.containerStyle}>
                <ViewHeader
                    screen={screen}
                    title_classname="text-xl px-6 py-1 text-gray-800"
                    hide_toolbar={props.hide_toolbar}
                    isSpreadsheet={false}
                />
                {fields && record ?
                    <GridHoc
                        screen={screen}
                        rowHeight={42}
                        
                    >

                        {fields.map((field, index) => (
                        
                            <FormField
                                key={field.name}
                                edition_mode={edition_mode}
                                field={field}
                                record={record}
                                ref={createRef()}
                                default_columns={default_columns} />

                        ))}




                    </GridHoc>

                    :
                    ""
                }

                <LoadingIndicator
                    ref={container_ref}
                    show={loading}
                    fullscreen

                />
            </Paper>
        </div>


    )

});

export default NewFormView;