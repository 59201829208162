import React, {Fragment} from 'react';
import { ResponsiveLine } from '@nivo/line'
import { observer } from 'mobx-react-lite'



const LineGraph = observer(function LineGraph(props) {

    const { graph } = props
    const x_legend = graph.x_legend
    const y_legend = graph.y_legend
    const x_format = graph.x_format
    const data = graph.screen.data_values

    console.log("LINE GRAPH VALUES")
    console.log(data)
    return (
        <>
            <ResponsiveLine
                data={data}
                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                xScale={{ type: 'point' }}
                // xScale={{
                //     type: 'time',
                //     format: x_format,
                //     useUTC: false,
                //     precision: 'day',
                // }}
                // xFormat={x_format}
                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: x_legend,
                    legendOffset: 36,
                    legendPosition: 'middle'
                }}
                // axisBottom={{
                //     format: '%d-%m-%y %H:%M:%S',
                //     tickValues: 'every 1 month',
                //     legend: x_legend,
                //     legendOffset: 36,
                //     legendPosition: 'middle'
                // }
                // }
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: y_legend,
                    legendOffset: -40,
                    legendPosition: 'middle'
                }}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
            />

        </>


    )

});
export default LineGraph;