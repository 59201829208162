import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite'
import SessionContext from '../../connection/SessionContext'
import {ScreenGroup} from '../../screen/ScreenGroup'
import AppContext from './AppContext'
import ContextMenuContext from './ContextMenuHandler';

export const AppContextProvider = observer(function AppContextProvider({children, widgets_registry}) {

  const connection = useContext(SessionContext)
  const context_menu = useContext(ContextMenuContext)
  // Bind the provider to the connection status, rerender if changed
  const [connection_status, setConn] = useState(connection.status)
  const value = new ScreenGroup(connection,false, context_menu, widgets_registry)

    return (
      <AppContext.Provider value={value}>
        {children}
      </AppContext.Provider>
    );

    
  })
  
