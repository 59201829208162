import React, {useEffect, Fragment} from 'react';
import BaseField from './BaseField.js';
import AsyncSelectField from './AsyncSelectField.js';
import SelectField from './SelectField.js'
import LookUpField from './LookUpField.js';
import FieldWrapper from './FieldWrapper'
import DateField from './DateField'
import BooleanField from './BooleanField'
import O2MField from './O2MField'
import TextField from './TextField'
import HtmlField from './HtmlField'
import BinaryField from './BinaryField'
import FormulaField from './FormulaField'
import FloatField from './FloatField'
import { observer } from 'mobx-react-lite'
import {widgets} from './widgets/widgets'
import FormButton from '../buttons/FormButton.js'
import FormDivider from './FormDivider'
import './html_field.css'
import DynamicOne2Many from './DynamicOne2Many.js';



const NO_WRAP_FIELDS = ['one2many','button','divider', 'dynamic_one2many']
const USE_WIDGET_CONTENT = ['button', 'binary', 'attachment','html','one2many', 'formula']

const fieldContent = ({field, record, value}) =>{
    return (
        <div className="base-text" style={{height:'inherit',...field.style || {}}}>
            {!field.hide_label ? <>{field.description}:</>:""} {field.get_string_value(record, value)}
        </div>
    )
}

const renderFormulaField = ({ props, value, onChange, handleFieldBlur, state_attrs, record }) => {
    const child_props = {...props, field:props.field.field_widget}
    return(
    <FormulaField {...props} value={value}>
        { props.field.content_only ?
            // <>{props.field.field_widget.get_string_value(record, value)}</>
            <>{fieldContent({field:props.field.field_widget, record, value})}</>
            :
            <>
            {<>{fields[props.field.formula_field_widget]({ props:child_props, value, onChange, state_attrs, handleFieldBlur, record })}</>}
            </>
          
            
        }
        
    </FormulaField>
    )
    
}

const fields = {
    'float': ({ props, value, onChange, handleFieldBlur, state_attrs, onFocus, record }) => <FloatField {...props} value={value} onFocus={onFocus} onBlur={handleFieldBlur} onChange={onChange} state_attrs={state_attrs} record={record} />,
    'char': ({ props, value, onChange, handleFieldBlur, state_attrs, onFocus }) => <BaseField {...props} value={value} onFocus={onFocus} onBlur={handleFieldBlur} onChange={onChange} state_attrs={state_attrs} />,
    'integer': ({ props, value, onChange, handleFieldBlur, state_attrs, onFocus }) => <BaseField {...props} value={value} onFocus={onFocus} onBlur={handleFieldBlur} onChange={onChange} state_attrs={state_attrs} />,
    'text': ({ props, value, onChange, handleFieldBlur, state_attrs, onFocus }) => <TextField {...props} value={value} onBlur={handleFieldBlur} onFocus={onFocus} onChange={onChange} state_attrs={state_attrs} />,
    'date': ({ props, value, onChange, state_attrs, handleFieldBlur }) => <DateField  {...props} value={value} onBlur={handleFieldBlur} state_attrs={state_attrs} onChange={onChange} />,
    'many2one': ({ props, save, state_attrs, handleFieldBlur }) => <AsyncSelectField saveOnBlur={save} state_attrs={state_attrs} onBlur={handleFieldBlur}   {...props} />,
    'selection': ({ props, onChange, state_attrs, handleFieldBlur }) => <SelectField onChange={onChange} state_attrs={state_attrs} onBlur={handleFieldBlur} {...props} />,
    'boolean': ({ props, state_attrs, handleFieldBlur, onChange, value, save }) => <BooleanField saveOnBlur={save} onBlur={handleFieldBlur} onChange={onChange} value={value} state_attrs={state_attrs} {...props} />,
    'multi_selection': ({ props, onChange, state_attrs, handleFieldBlur }) => <SelectField onChange={onChange} multi={true} onBlur={handleFieldBlur} state_attrs={state_attrs}  {...props} />,
    'one2many': ({ props, handleInnerOnChange, state_attrs }) => <O2MField {...props} state_attrs={state_attrs} onChange={handleInnerOnChange} />,
    'dynamic_one2many': ({ props, handleInnerOnChange, state_attrs }) => <DynamicOne2Many {...props} state_attrs={state_attrs} onChange={handleInnerOnChange} />,
    'multi_link': ({ props, save, state_attrs, handleFieldBlur }) => <AsyncSelectField saveOnBlur={save} state_attrs={state_attrs} onBlur={handleFieldBlur}  {...props} multi={true} styles={props.styles} />,
    'datetime': ({ props, value, onChange, state_attrs, handleFieldBlur }) => <DateField  {...props} value={value} state_attrs={state_attrs} onBlur={handleFieldBlur} onChange={onChange} />,
    'lookup':({ props, value, onChange, handleFieldBlur, state_attrs }) => <LookUpField {...props} value={value} onBlur={handleFieldBlur} onChange={onChange} state_attrs={state_attrs} />,
    'html': ({ props, value, onChange, handleFieldBlur, state_attrs }) => <HtmlField {...props} value={value} onBlur={handleFieldBlur} onChange={onChange} state_attrs={state_attrs} />,
    'binary':({ props, value, onChange, handleFieldBlur, state_attrs }) => <BinaryField {...props} value={value} onBlur={handleFieldBlur} onChange={onChange} state_attrs={state_attrs} />,
    'formula':({ props, value, onChange, handleFieldBlur, state_attrs, record }) => renderFormulaField({ props, value, onChange, handleFieldBlur, state_attrs, record }),
    'button':({ props, state_attrs, record }) => 
    <FormButton {...props} style={props.field.style || {}}  color={props.field.button_variant} 
        onClick={(e) => {
            if(props.edition_mode){
                return
            }
            if(record){
                props.field.screen.set_active_record(record)
            }
            
            props.field.action.execute([record], false)
        }} 
        state_attrs={state_attrs}>{props.field.description}</FormButton>,
    'divider':({ props, state_attrs, record }) => <FormDivider {...props} state_attrs={state_attrs} text={props.field.description}/>,
    'attachment': ({ props, value, onChange, handleFieldBlur, state_attrs }) => <BinaryField {...props} value={value} onBlur={handleFieldBlur} onChange={onChange} state_attrs={state_attrs} />,



}
const Field = observer(function Field(props) {
    const { field, record, edition_mode } = props
    const value = field.get_value(record)
    const state_attrs = props.parent_state_attrs ? props.parent_state_attrs:field.get_state_attrs(record) || {}
    const content_only = props.content_only != undefined ? props.content_only:field.content_only
    // Use a cleanup hook when unmount for remove the fieldInEdition Prop
    function onChange(e, value) {
        // record.setFieldInEdition(field.name)
        record.setFieldInEdition(field.name)
        return field.set_value(value, record)
    }
    useEffect(() => {
        
        return function cleanup() {
            
            record.setFieldInEdition(false)
            
            
        };
      },[]);

    function onBlur(e,new_value){
        
        record.setFieldInEdition(false)
        return field.custom_on_change({value:new_value,record:record})
    }

    function onFocus(e){
        // record.setFieldInEdition(field.name)
        // field.set_edition(record)
        
    }
    
    const getFieldContent = () =>{
        const type = field.type
        if(USE_WIDGET_CONTENT.includes(type)){
            return getFieldWidget()
        }

        return (
            <>{fieldContent({field, record})}</>
            
            // <div className="base-text" style={field.style || {}}>
            //     {!field.hide_label ? <>{field.description}:</>:""} {field.get_string_value(record)}
            // </div>
        )
    }

    const getFieldWidget = () => {
        
        
        const type = field.type
        if(field.widget){
            const widget = widgets.get(type, field.widget)
            if(widget){
                return (<>{widget({ ...props, value, onChange, state_attrs, onFocus, handleFieldBlur:onBlur, record })}</>)
            }
            
        }
        
        if (fields[type]) {

                    // InfoIcon
                    // <Tooltip
                    //     content={field.tooltip.content}
                    //     template={field.tooltip.template}
                    //     >
                    //         {field_component}
                    // </Tooltip>
            const field_component = (<>{fields[type]({ props, value, onChange, state_attrs, onFocus, handleFieldBlur:onBlur, record, noWrap:props.noWrap })}</>)

            
            return field_component
            
            // return (<>{fields[type]({ props, value, onChange, state_attrs, onFocus, handleFieldBlur:onBlur, record })}</>)
        }
    }

    const getField = () => {
        if (state_attrs.invisible) {
            return (<></>)
        }
        if(content_only){
            return getFieldContent()
        }
        return getFieldWidget()
    }



    return (
        <>
        
            {field.no_wrap || props.noWrap || NO_WRAP_FIELDS.includes(field.type) ?
                <>
                    {getField()}
                </>

                :
                <FieldWrapper 
                    {...props}
                    state_attrs={state_attrs} 
                    value={value} 
                    style={{ display: state_attrs.invisible ? 'none' : '', ...props.style }} 
                    customInputStyle = { props.style }
                    >

                    {getField()}
                    

                </FieldWrapper>
            }
        </>



    )

});

export default Field;