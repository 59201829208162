import React, { useEffect, useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { html } from '@codemirror/lang-html'
import { python } from '@codemirror/lang-python'
import { css } from '@codemirror/lang-css'



function CodeText(props) {
    const {
        field,
        onFocus,
        state_attrs,
        record,
        extensions,
        language,
    } = props;
    const wrapperRef = React.useRef(null);
    const [height, setHeight] = useState(200)

    useEffect(() => {
        if (!wrapperRef.current || !field.screen.use_view_layout) return; // wait for the elementRef to be available
        const resizeObserver = new ResizeObserver(() => {
          if(wrapperRef.current){
            const new_height = (wrapperRef.current.getBoundingClientRect().height)
            setHeight(new_height)
          }
        
         
        });
        resizeObserver.observe(wrapperRef.current);
        return () => resizeObserver.disconnect(); // clean up 
      }, []);

    return (
        <div ref={wrapperRef} className='overflow-scroll' style={{  height: '100%' }}>
            
            <CodeMirror 
                value={props.value} 
                height={height}
                editable={!state_attrs.readonly}
                extensions={extensions} 
                onChange={(val, ViewUpdate) => { props.onChange(ViewUpdate, val) }}
                placeholder={field.description}
                // theme={githubLight}
            
            />

        </div>        
         


            

        
    )
}

export function PythonEditor(props){
    return <CodeText
                extensions={[python()]}
                language='py'
                {...props}
            />
}

export function JavascriptEditor(props){
    return <CodeText
                extensions={[javascript({ jsx: true })]}
                language='js'
                {...props}
            />
}

export function HTMLEditor(props){
    return <CodeText
                extensions={[html()]}
                language='html'
                {...props}
            />
}

export function CSSEditor(props){
    return <CodeText
                extensions={[css()]}
                language='css'
                {...props}
            />
}