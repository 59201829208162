import React from 'react';
import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"

export const ContextMenuContext = React.createContext();



export class ContextMenuHandler {
    source_object = false
    target_element = false
    status = {}
    options = []
    active_record = false
    title=''


    constructor() {
        makeAutoObservable(this, {
            source_object: observable,
            target_element:observable,
            set_source_object: action,
            options:observable,
            active_record:observable,
            title:observable,
            open:action,
            close:action,
        }
        )
        this.status = {}
        this.target_element = false
        this.source_object = false


    }

    set_source_object(source_object) {
        this.source_object = source_object
    }

    open(source_object, element, active_record=false, title=''){
        this.source_object = source_object
        this.target_element = element
        if(active_record){
            this.active_record = active_record
        }
        this.title = title
        this.options = this.source_object.get_context_menu_options(active_record).map(function(option){
            
            option.onClick = ()=>{
                option.action()
                this.close()
            }
            return option
        }.bind(this))

    }
    close(){
        this.source_object = false
        this.target_element = false
        this.active_record = false
    }


   
}



export default ContextMenuContext;