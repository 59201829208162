import React, { useState, useEffect, Fragment, useContext } from 'react';
import DropDownMenu from './DropDownMenu.js';
import {ContextMenuContext} from 'kalenis-portal-frontend-main'
import { observer } from 'mobx-react-lite'




const ContextMenu = observer(function ContextMenu(props) {

    
    const context_menu = useContext(ContextMenuContext)
    const element = context_menu.target_element
    const close = () =>{context_menu.close()}
    
    return (
        <>
            <DropDownMenu
                id="context-menu"
                anchor={element}
                options = {context_menu.options}
                open={Boolean(element)}
                onClose={close}
            >
            </DropDownMenu>
        </>



)

});
export default ContextMenu;