import React, { useState, Fragment } from 'react';
import { observer } from 'mobx-react-lite'
import FormButton from '../ui/buttons/FormButton';

const ViewEditionButton = observer(function ViewEditionToolbar(props) {
    const { name, callback, value} = props
    
    

    return (
        <div className='text-gray-600 border-r border-l px-2 border-gray-300 text-xs font-medium'>
            <FormButton
                variant="list"
                state_attrs={{}}
                className=" truncate text-xs py-1"
                color={'transparent'}
                onClick={(e) => {callback(value)}}>
                    {name}
             </FormButton>
        </div>
        
    )

});


const ViewEditionToolbar = observer(function ViewEditionToolbar(props) {
    const { screen, editor } = props
    const draft_operations = editor.draft_operations
    console.log("Draft operations getted")
    console.log(draft_operations)

    return (
        <>
            {/* {Object.keys(draft_operations).map((name, index) => (
                <div>
                    <ViewEditionButton {...draft_operations[name]}/>
                </div>

            ))} */}
            <div className='text-gray-600 border-r  px-2 border-gray-300 text-xs font-medium'>Pantalla: {screen.editor.current_breakpoint}</div>
            
        </>




    )

});
export default ViewEditionToolbar;