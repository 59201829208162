import { makeAutoObservable, autorun, runInAction, action, observable, makeObservable, computed } from "mobx"
import { evalCondition, environment } from '../common'

export class AttachmentsHandler {
    screen = false

    constructor(screen) {
        // makeObservable(this, {
        //     loading: observable,
        //     dependencies: observable,
        //     inputs: observable,
        //     reload: action,
        //     update_inputs:action,
        //     setLoading:action
        // })

        this.screen = screen        


    }

    async get_by_id(id, data_url=false){
        const args = {
            'view': this.screen.view.id
        }
        let attach = await this.screen.connection.dispatch('GET', `/attachment/${id}`, args, false, false, true)
        if(data_url && attach.data){
            if(environment() == 'web'){
                attach = window.URL.createObjectURL(attach.data)
                return attach
            }
            else{
                const read = (data) => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (event) => resolve(event.target.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(data);
                  });
                return read(attach.data)
            }
                
        }
        
        
    }

    async resources_count(record_id){
        let args = {}
        if(!this.screen.attachment_list_action && !this.screen.notes_list_action){
            return {}
        }
        return await this.screen.connection.dispatch('GET', `/attachment/count/${this.screen.view.id}/${record_id}`, args, false, false, false)
    }
    
    
}
