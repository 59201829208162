import { action, makeObservable, observable } from "mobx"

export class ScreenEditor {
    permissions = {}
    screen = {}
    design_mode = false
    current_breakpoint = 'default'
    draft_operations = {}

    get fields_permission(){
        return this.permissions.new_field
    }

    get view_edition_permission(){
        return this.permissions.view_edition
    }
    get edition_options(){
        const options = []
        if(this.fields_permission){
            options.push({'id':'add_field','label':'+ Añadir Campo', 'onClick':()=>{this.openFieldScreen()}})
        }
        if(this.view_edition_permission){
            options.push({'id':'edit_view','label':'Editar Vista', 'onClick':()=>{this.openViewScreen()}})
            options.push({'id':'view_design_mode','label':'Modo Diseño', 'onClick':()=>{this.toogle_design_mode()}})
        }

        return options
    }
    
    constructor(config, screen, permissions={}) {
        this.config = config;
        this.screen = screen;
        this.permissions = permissions || {};
        this.draft_operations = {}
        makeObservable(this,{
            permissions:observable,
            config:observable,
            design_mode:observable,
            toogle_design_mode:action,
            current_breakpoint:observable,
            set_current_breakpoint:action,
            draft_operations:observable,
            add_draft_operation:action,
            remove_draft_operation:action

        })   
       

    }

    toogle_design_mode(){
        this.design_mode = !this.design_mode
    }
    set_current_breakpoint(current_breakpoint){
        this.current_breakpoint = current_breakpoint
    }

    async executeAndReload(record,screen){
        await screen.default_save_action.execute([record],false,false,true)
        
        await this.screen.loadFields({},{},true)
        
    }

    new_screen_callback(screen){
        
        // let new_values = {
        //     'view':this.screen.id,
        //                  'view.':{'id':this.screen.id,'rec_name':this.screen.view.name},
        //                  'ds_type': this.screen.view.data_source_type,
        //                  'view_model_': this.screen.model
        //                 }
        // if(screen.active_record){
        //     screen.active_record.set_values(new_values)
        // }
        
        
        
    }

    editable_field(){
        return (this.screen.editable_view && this.config.get_definition('new_field'))
    }

    openFieldScreen(field_id=null){
        if(!this.screen.editable_view){
            return
        }
        const view_definition = this.config.get_definition('new_field')
        if(!view_definition){
            return
        }
        let search = {}
        if(field_id){
            search = {'current_search':[['id','=',field_id]]}
        }
        const field_screen = this.screen.modal_childs.addScreen(view_definition, false, search, {}, true, true, this.screen, true,true,true, (screen)=> this.new_screen_callback(screen))
        let actions_map = {}
        // let default_save_action = field_screen.default_save_action
        
        actions_map['save'] = (records) => { this.executeAndReload(records[0], field_screen) }
        field_screen.set_actions_map(actions_map)
        return field_screen
        
        
    }

    async reloadScreen(records, screen, current_screen){
        await screen.default_save_action.execute(records,false,false,true)
        await current_screen.reinitialize()
        
    }

    openViewScreen(){
        if(!this.screen.editable_view){
            return
        }
        const view_definition = this.config.get_definition('view_edition')
        let actions_map = {}
        const search = {'current_search':[['id','=',this.screen.id]]}
        const view_screen = this.screen.modal_childs.addScreen(view_definition, false, search, {}, true, true, this.screen, true,true,true, (screen)=> {})
        actions_map['save'] = (records) => { this.reloadScreen(records, view_screen, this.screen) }
        view_screen.set_actions_map(actions_map)
    }

    add_draft_operation(action, data){
        let draft_operations = {...this.draft_operations}
        draft_operations[action] = data
        console.log("SETTING DRAFT OPERATION")
        console.log(draft_operations)
        this.draft_operations = draft_operations

    }
    remove_draft_operation(name){
        if(this.draft_operations[name]){
            delete this.draft_operations[name]
        }
    }
    



}
   

    