import React from 'react';
import Tooltip from '../Tooltip'
import { ReactComponent as DownIcon } from '../icons/svg/chevron-down.svg';
import { ReactComponent as UpIcon } from '../icons/svg/chevron-up.svg';
import IconButton from '../buttons/IconButton.js';
import { observer } from 'mobx-react-lite'

const FormDivider = observer(function FormDivider(props) {

    const { field, record } = props
    const style = field.get_style(record)
    
    const content = props.content ? props.content:field.get_content(record)
    const {collapsible, collapsed } = field

    return (
        <div
            style={{ display: props.invisible ? 'none' : '', ...style }}
            className="bg-modal-background text-gray-700 text-sm font-medium rounded px-2 w-full flex flex-row items-center justify-between">
            {content}
            {
                collapsible ?
                    <IconButton 
                    onClick={(e) => { field.collapse(record) }} 
                    color="transparent"
                    size="md"
                    style={{color:'inherit'}}
                    shape="circle"
                    title="Info">
                            {
                                collapsed ?
                                    <DownIcon  
                                    className="fill-current"
                                    />
                                    :
                                    <UpIcon  
                                    className="fill-current"
                                    />

                            }
                            
                    </IconButton>
                    :
                    ""
            }
            

            {
                (props.show_tooltip && field.tooltip) ?
                    <Tooltip
                        content={field.tooltip.content}
                        template={field.tooltip.template}
                    />
                    :
                    ""

            }



        </div>


)

});

export default FormDivider;