import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"
import { Screen } from '../Screen'
import {findTags, setImgPlaceholders} from '../../html_template'
import { environment } from '../../common'


export class HTMLScreen extends Screen {
    template_source = ""
    template_field = ""
    view_template = ""

    get template_field_object() {
        let field = false
        if (this.template_field && this.fields.length) {
            field = this.fields.find(f => f.name === this.template_field)
        }

        return field
    }
    get content() {
        let content = ""
        if (this.template_source === 'view_field' && this.active_record.id) {
            content = this.template_field_object.get_value(this.active_record)
        }
        else {
            content = this.view_template
        }

        return content

    }
    constructor(group, 
        view, 
        connection, 
        navigate, 
        initial_search, 
        route_state, 
        is_modal, 
        parent, 
        initialize_fields = true, 
        initialize_actions = true, 
        initialize_data=true, 
        initialize_callback=false, 
        fileHandler=false,
        data_callback=false,
        on_load_data=false) {
        
        on_load_data = (screen, records) => {screen.render_template(records)}
        super(group, 
                view, 
                connection, 
                navigate, 
                initial_search, 
                route_state, 
                is_modal, 
                parent, 
                initialize_fields, 
                initialize_actions, 
                initialize_data,
                initialize_callback,
                fileHandler,
                data_callback,
                on_load_data)
        
        this.template_source = view.html.template_source
        this.template_field = view.html.template_field
        // this.view_template = view.html.view_template
        this.view_template = ""
        this.processing = true
        

        makeObservable(this, {
            view_template: observable,
            template_field: observable,
            setViewTemplate:action,
            render_images:action
        })

    }

    setViewTemplate(content){
        this.view_template = content
    }

    async render_images(content){
        let {tags, html_tree} = findTags(content, 'img')
        for(let img of tags){
            img.src = await this.attachment_handler.get_by_id(img.id, true)
            img.className += 'skeleton'
            img.alt = ''
            
        }
        this.setViewTemplate(html_tree.outerHTML)
        return html_tree.outerHTML
    }

    async get_mobile_image(imgTag){
        var idRegex = /id=['"]([^'"]*)['"]/;
        var idMatch = imgTag.match(idRegex);
        var idValue = idMatch ? idMatch[1] : null;
    
        console.log("Image tag:", imgTag);
        console.log("ID attribute value:", idValue);
        let src = await this.attachment_handler.get_by_id(idValue, true)
        let complete_tag = imgTag.replace('<img', '<img src="' + src + '"');
        return [imgTag, complete_tag]
    }

    async render_mobile_images(content){
        var imgRegex = /<img\b[^>]*\sid=['"]([^'"]*)['"][^>]*>/g;
        var imgTags = content.match(imgRegex);
        let prms = []
        console.log("RENDERING MOBILE IMAGES....")

        if (imgTags) {
            imgTags.forEach(function (imgTag) {
                prms.push(this.get_mobile_image(imgTag))

            }.bind(this));
          }        
        
        return Promise.all(prms).then(function(res){
            res.forEach(function(img){
                content = content.replace(img[0], img[1]);
            })
            this.setViewTemplate(content)
            return content
        }.bind(this))
        
    }
    
    async render_template(records=[]){
        if(!records.length){
            records = this.data.records
        }
        console.log(records)
        const abortController = new AbortController();
        let args = {}
        args.ids = records.map(function(rec){return rec.id})
        args.view_id = this.view.id
        let content = await this.connection.dispatch('GET', '/view/render_html_content', args, false, false, false, abortController)
        if(environment() != 'web'){            
            this.setViewTemplate(content.content)
            this.set_processing(false)
            return await this.render_mobile_images(content.content)
            
        }
        
        this.setViewTemplate(setImgPlaceholders(content.content))
        this.set_processing(false)

        return await this.render_images(content.content)
        
    }





}