import { makeObservable,  action, observable } from "mobx"
import { v4 as uuidv4 } from 'uuid';



class SnackNotification {
    id = uuidv4()
    message = ""
    level = ""
    timeout = ""
    persistant = false
    manager = false
    component = false
    constructor(attributes, manager) {
        this.message = attributes.message
        this.level = attributes.level || 'sucessfull'
        this.persistant = attributes.persistant || false
        this.timeout = attributes.persistant ? false : attributes.timeout ? attributes.timeout : 3000
        this.manager = manager
        this.component = manager.snack_component ? manager.snack_component:false
        if (this.timeout) {
            setTimeout(() => {
                this.remove(this.id)
            }, this.timeout);
        }
    }

    remove() {
        this.manager.removeSnack(this.id)
    }


}

class DialogNotification {
    id = uuidv4()
    message = ""
    body_component = false
    actions = []
    constructor(attributes, manager, component) {
        makeObservable(this,{
            actions:observable,
            message:observable,
        })
        this.message = attributes.message
        this.html = attributes.html
        this.actions = attributes.actions ? attributes.actions:[]
        this.manager = manager
        this.body_component = component
        

    }

    async callbackWrapper(callback) {
        if (!callback) {
            return this.remove()
        }
        else {
            let res = await callback()

            if (res) {
                return this.remove()
            }

        }
    }
    remove(){
        this.manager.removeDialog(this.id)
    }


}


export class NotificationManager {
    snack_queue = []
    dialog_queue = []
    snack_timeout = 3000
    snack_component = ""
    dialog_component = ""

    get current_snack_messages(){
        return this.snack_queue.map(function(snack){return snack.message})
    }

    constructor() {
        makeObservable(this, {
            snack_queue: observable,
            dialog_queue: observable,
            snack_component:observable,
            dialog_component:observable,
            addSnack: action,
            removeSnack: action,
            addDialog: action,
            removeDialog:action,
            setSnackComponent:action,
            setDialogComponent:action

        })
    }

    /**
    * Set Snack Component
    * @param {<Component>}  component -Component to override Snack Notification    
     * @return void
    */
    setSnackComponent(component){
        this.snack_component = component;
    }
     /**
    * Set Dialog Component
    * @param {<Component>} component -Component to override Dialog Component
     * @return void
    */
    setDialogComponent(component, extra_props){
        this.dialog_component = {component,extra_props};
    }

    /**
    * Add New Snack Notification
    * @param {Object}  value - An object containing: 
    * * message: {string}
    * * level:oneOf ['successfull','error']. 
    * * persistant:{boolean} Avoid autoclose
    * * timeout: {int} 
 
     * @return SnackNotification
    */
    addSnack(value) {
        if(this.current_snack_messages.includes(value.message)){
            return
        }
        let newSnack = new SnackNotification(value, this)
        this.snack_queue.push(newSnack)
        return newSnack
    }

    removeSnack(id) {
        const snack_index = this.snack_queue.findIndex((element) => element.id === id);
        this.snack_queue.splice(snack_index, 1)
        return false
    }

    /**
    * Add New Dialog Notification
    * * @param {object}  value - An object containing: message: {string}, html {boolean}, actions:{array}. 
    * * * Each action should have: 
    * * * *   name {string}: Name to be displayed
    * * * *   color {string}: Button Color(primary,secondary,disabled)
    * * * *   callback {function}: Executed on button clicked. If true its returned, the dialog will be closed.
    *  @param {boolean} add_close: Add close action automatically
    *  @param {function} component: Component to be rendered inside the modal instead of default.
     * @return {void} ""
    */
    addDialog(value, add_close, component) {
        const dialog = { ...value }

        if (add_close) {
            const close_action = {
                name: 'Cerrar',
                color: 'disabled',

            }
            if (dialog.actions && Array.isArray(dialog.actions)) {
                dialog.actions.unshift(close_action)
            }
        }
        
        this.dialog_queue.push(new DialogNotification(dialog, this, component))


    }
    removeDialog(id) {
        const dialog_index = this.dialog_queue.findIndex((element) => element.id === id);
        this.dialog_queue.splice(dialog_index, 1)
        return false
    }



}