import { makeAutoObservable, autorun, runInAction, action, observable, makeObservable, computed } from "mobx"
import { evalCondition, browseObject, evalExpr, evalRecordExpr } from '../common'
const DEFAULT_ACTIVE_RECORD = ['form', 'html']
import {StateAttrs} from './StateAttrs'
import {NewCalculatedValue} from './NewCalculatedValue'
import {BinaryValue} from './BinaryValue'

class CalculatedValue {
    field = false
    value = null
    inputs = {}
    loading = true
    initialized = false

    constructor(field, record) {
        makeObservable(this, {
            value: observable,
            reload: action,
            toogleLoading: action,
            initialized: observable,
            // loading:observable,
        })
        this.field = field
        this.inputs = field.get_dependencies(record)
        // this.value = browseObject(record._values, field.expression)
        this.loading = false
        this.reload(record)


    }

    get_value() {
        return this.value
    }

    shouldReload(record) {
        let res = false
        for (let i in this.inputs) {
            if (this.inputs[i] !== record._values[i]) {
                res = true
            }
        }

        return res
    }

    toogleLoading() {
        this.loading = !this.loading
        
        // this.loading=true
    }

    async reload(record) {
        //Try to find de value on the record
        if (this.loading) {
            return
        }
        this.toogleLoading()
        let value = browseObject(record._values, this.field.expression)
        if (!value) {
            // alert("A network request will be made")
            value = await this.field.get_lookup_value(record)
        }
        
        runInAction(() => {
            
            
            this.value = value
            this.inputs = this.field.get_dependencies(record)
            this.toogleLoading()
            this.initialized = true;

        })

    }


}

export class RecordGroup {
    records = []
    count = 0
    loading = true
    loadingInitialData = false
    current_page = 1
    next_new_id = 0
    _changed = false
    pagination_enabled = false
    default_page_size = 0
    page_size_options = []
    limit = null
    show_records_count = false
    single_record = false


    get o2m_fields() {
        return this.screen.fields.filter(function (field) { return (field.type == 'one2many' && field.initialized === true) })
    }
    get page_qty() {
        return Math.ceil((this.count && this.limit) ? this.count / this.limit : 0)
    }


    constructor(connection, attrs, screen) {
        makeObservable(this, {
            records: observable,
            count: observable,
            loadData: action,
            setInitialLoading: action,
            setLoading: action,
            loadingInitialData: observable,
            loading: observable,
            addRecord: action,
            clearRecords:action,
            setChanged: action,
            removeRecords: action,
            replaceRecord: action,
            o2m_fields: computed,
            nextPage: action,
            prevPage: action,
            limit: observable,
            current_page: observable,
            setCurrentPage: action,
            pagination_enabled: observable,
            show_records_count: observable,
            single_record:observable,
        })
        this.connection = connection
        this.screen = screen
        this.model = screen.model
        this.pagination_enabled = attrs.pagination_enabled
        if (this.pagination_enabled) {
            this.default_page_size = attrs.default_page_size
            this.limit = attrs.default_page_size
            this.page_size_options = attrs.page_size_options
        }
        this.show_records_count = this.pagination_enabled ? true : attrs.show_records_count
        this.data_callback = this.screen.data_callback
        this.on_load_data = this.screen.on_load_data
        this.single_record = this.screen.type == 'form'
        




    }

    get_record_by_index(index) {
        return this.records[index]
    }
    get_record_by_id(id) {
        return this.records.find(rec => rec.id === id)
    }

    setChanged(value) {
        this._changed = value
    }

    /**
    * Next New Id
    * @return Returns the next id for unsaved records(always negative) ""
    */
    get_next_new_id() {
        this.next_new_id -= 1
        return this.next_new_id
    }



    /**
     * Add New Record
     * @param {Object}  {value: Values for the new record. 
     * If undefined, defaults will be used,index:Position of the group to add the record }.
     
     * @return Record added ""
     */
    addRecord({ values, index, group_parent }) {
        const i = index ? index : this.records.length
        if (!values) {
            values = {}
        }
        
        const record = new Record(values, this.screen, this, group_parent)
        if (!Object.keys(values).length) {
            record.setDefaultValues()
        }
        this.records.splice(i, 0, record)

        return record
    }
    /**
     * Remove all records from group
     * @return {void}
     */
     clearRecords() {
        this.records = []
        this.next_new_id = 0
        this.count = 0
        

    }

    /**
     * Remove records from group
     * @param {array}  records to remove
     * @return {void}
     */
    removeRecords(records) {

        records.forEach(function (record) {
            this.records.splice(this.records.indexOf(record), 1)
        }.bind(this))
        if(this.screen.selected_records){
            this.screen.set_selected_record([])
        }
        

    }

    /**
     * Replace a record for other. Used on save executions
     * @param {old_record} Old Record  
     * @param {new_record} New Record
     * @return {void}
     */
    replaceRecord(old_record, new_record) {
        new_record.group = this;
        this.records[this.records.indexOf(old_record)] = new_record
        if(this.screen.active_record && this.screen.active_record.id == old_record.id){
            this.screen.set_active_record(new_record)
        }


    }
    setInitialLoading(value) {
        this.loadingInitialData = value
    }
    setLoading(value) {
        this.loading = value
    }


    /**
     * Move to next page of data.
     * @return {void}
     */
    setLimit(value) {

        this.limit = value
        //HERE => check set limit and reset page number to 0. Maybe is the run in action miss on the load data
        this.loadData(false, true)


    }

    /**
     * Move to next page of data.
     * @return {void}
     */
    nextPage() {
        const next_page = this.current_page + 1
        if (next_page > this.page_qty) {
            return
        }
        this.current_page = this.current_page + 1
        this.loadData()


    }
    /**
     * Move to prev page of data.
     * @return {void}
     */
    prevPage() {
        const prev_page = this.current_page - 1
        if (prev_page < 1) {
            return
        }
        this.current_page = this.current_page - 1
        this.loadData()

    }

    async getRecordsCount() {

        if (this.connection.status) {
            const abortController = new AbortController();
            let count = 0
            let args = {
                view_id: this.screen.id,
                search: this.screen.current_search,
                order: this.screen.order,
                model: this.screen.model,
                count: true
            }


            if (!this.screen.headless) {
                args['action_id'] = this.screen.action_id ? this.screen.action_id : ""
                args['action_params'] = this.screen.action_params
            }

            count = await this.connection.dispatch('GET', '/data/get', args, false, false, false, abortController)

            runInAction(() => {

                this.count = count ? count:0

            })




        }

    }

    setCurrentPage(value) {
        this.current_page = value
       
    }


    async loadData(force_count = false, reset_page = false, extra_args = {}) {

        if (!this.loadingInitialData && this.connection.status) {
            const abortController = new AbortController();
            let new_data = []
            // Partial: Only retrieve the data, getted records will not be added to group, allowing to use data in other ways
            let partial = extra_args.partial
            this.loading = true

            let args = {
                view_id: this.screen.id,
                search: this.screen.current_search,
                order: this.screen.order,
                group_by:this.screen.group_by,
                model: this.screen.model
            }
            if (this.pagination_enabled) {
                let current_page = this.current_page - 1
                args['limit'] = this.limit
                if (reset_page) {
                    current_page = 0
                    this.setCurrentPage(1)

                }
                args['offset'] = current_page * this.limit
            }

            if (!this.screen.headless) {
                args['action_id'] = this.screen.action_id ? this.screen.action_id : ""
                args['action_params'] = this.screen.action_params
            }

            // if (this.screen.type === 'graph') {

            //     args['graph'] = this.screen.graph
            // }
            args = { ...args, ...extra_args }


            
            //Prevent data fetching in form views if no id
            if ((this.single_record && !args.search.length && !args.action_params.length) || this.screen.data_origin === 'none') {
                
                runInAction(() => {
                    

                    if (DEFAULT_ACTIVE_RECORD.includes(this.screen.type)) {
                        this.clearRecords()
                        const record = this.addRecord({})
                        this.screen.set_active_record(record)
                        
                    }


                })

            }
            else {

                new_data = await this.connection.dispatch('GET', '/data/get', args, false, false, false, abortController)

                if(new_data && !partial){
                    runInAction(() => {
                        this.records = new_data.map(function (row) {
    
                            return new Record(row, this.screen, this)
                        }.bind(this))
                        if (DEFAULT_ACTIVE_RECORD.includes(this.screen.type)) {
                            if(this.records.length){
                                this.screen.set_active_record(this.records[0])
                            }
                            
                        }
    
    
    
                    })

                }
                else if(new_data == false){
                    this.records = []
                    this.screen.set_active_record(false)
                }
                

            }






            runInAction(() => {
                if (this.loadingInitialData) {
                    this.loadingInitialData = false;
                    //count records after the initial loading
                    
                    if (this.show_records_count) {
                        this.getRecordsCount()
                    }


                }
                this.loading = false;


            })
            
            //REmoved force: Always count on data fetch.
            if (this.show_records_count) {
                this.getRecordsCount()
            }
            this.onLoadData(this.records)

            return new_data
           
           




        }

    }
    runCallback(record){
        if(this.data_callback){
            this.data_callback(record)
        }
    }
    onLoadData(records){
        if(this.screen.on_load_data){
            this.screen.on_load_data(this.screen, records)
        }
    }


}

export class Record {
    id = -1
    _values = {}
    _changed = {}
    _errors = {}
    state_attrs = {}
    base_style = {}
    fields_style = {}
    conditional_styles = {}
    resources_count = {}
    group = false
    groupby_parent = false
    groupby_expanded = false
    field_in_edition = false
    on_change_callback = false

    get attachments_count(){
        if(this.resources_count && this.resources_count.attachments){

            return this.resources_count.attachments.total ? this.resources_count.attachments.total:0
        }
        
    }

    get notes_count(){
        if(this.resources_count && this.resources_count.notes){

            return this.resources_count.notes.total ? this.resources_count.notes.total:0
        }
        
    }

    get keywords(){
        return {
            '_view':() => {return this.screen.view.id},
            '_parent_view':() => {
                if(this.screen.parent){
                    return this.screen.parent.view.id
                }
                return null
                
            },
            '_parent_data_source':() => {
                if(this.screen.parent){
                    return this.screen.parent.view.data_source_type
                }
                return null
                
            },
            '_model':() => {
                return this.screen.model
            },
            '_parent_model':() => {
                if(this.screen.parent){
                    return this.screen.parent.model
                }
                return null
                
            },
            '_root_parent_record': () => {
                const root_screen = this.screen.root_screen
                if(root_screen){
                    if(root_screen.active_record){
                        return this.screen.root_screen.active_record
                    }
                    // REVIEW: Currently a record is not set as active_record when selected...
                    // so take the first selected record... check if is desirable to set_active_record for last selected record.
                    else if(root_screen.selected_records[0]){
                        return root_screen.selected_records[0]
                    }
                }
                
                return null
            },
            '_root_parent_record_id': () => {
                const root_parent_record = this.keywords['_root_parent_record']()
                if(root_parent_record){
                    return root_parent_record.id
                }
                return null
            },
            '_root_parent_model':()=> {
                return this.screen.root_screen.model
            },
            '_json_values':() => {return JSON.stringify(this.get_raw_values())},
            '_template_values':() => {return JSON.stringify(this.get_template_values())}
        }
    }

    get backend_values() {
        let values = {}
        for (let v in this._values) {
            if (this.isField(v)) {
                values[v] = this._values[v]

            }
        }
        if(!values['id']){
            values['id'] = this.id
        }
        let fnames = Object.keys(values)
        this.listener_fields.forEach(function (lf) {
            if (fnames.includes(lf.name)) {
                // values[lf.name] = this._values[lf.name].get_value()
                delete values[lf.name]
            }
        }.bind(this))
        this.binary_fields.forEach(function (bf) {
            if (fnames.includes(bf.name)) {
                if(values[bf.name] && values[bf.name].hasOwnProperty('get_value')){
                    values[bf.name] = this._values[bf.name].get_value()
                }
                else{
                    values[bf.name] = null
                }
                
            }
        }.bind(this))
        this.formula_fields.forEach(function (lf) {
            if (fnames.includes(lf.name)) {
                // values[lf.name] = this._values[lf.name].get_value()
                delete values[lf.name]
            }
        }.bind(this))
        return values
    }

    get required_fields() {
        return Object.keys(this.state_attrs).filter(function (fname) {
            return this.state_attrs[fname].required

        }.bind(this)).map(function (fname) {
            return this.screen.fields.find(f => f.name === fname)
        }.bind(this))
    }
    // get template_fields(){
    //     return this.screen.fields.filter(function (f) {
    //         return f.include_in_template
    //     })
    // }
    get listener_fields() {
        return this.screen.fields.filter(function (f) {
            return f.type === 'lookup'
        })
    }
    get binary_fields() {
        return this.screen.fields.filter(function (f) {
            return f.type === 'binary'
        })
    }
    get formula_fields() {
        return this.screen.fields.filter(function (f) {
            return f.type === 'formula'
        })
    }
    get formula_fnames(){
        return this.formula_fields.map(function(f){return f.name})
    }

    get field_with_defaults(){
        return this.screen.fields.filter(function (f) {
            return f.default_value
            
        })
    }
    get parent_default_fields() {
        return this.screen.fields.filter(function (f) {
            if(f.default_value){
                return f.default_value.includes("_parent_record")
            }
            
        })
    }

    get parent_selected_records_fields(){
        return this.screen.fields.filter(function (f) {
            if(f.default_value){
                return f.default_value.includes("_parent_selected_records")
            }
            
        })
    }

    get mobile_record_title() {
        return evalRecordExpr(this, this.screen.mobile_record_title, this.screen.parent.active_record).str || ""
        // return evalExpr(this._values, this.screen.mobile_record_title).str || ""
    }

    get groupby_childs(){
        return this.group.records.filter(function (rec) {
            return rec.groupby_parent == this.id
            
        }.bind(this))
    }

    



    constructor(attributes, screen, group, groupby_parent=false) {
        makeObservable(this, {
            _set_state_attrs: action,
            set_value: action,
            set_values: action,
            set_changed: action,
            set_conditional_styles:action,
            _values: observable,
            _changed: observable,
            _errors: observable,
            state_attrs: observable,
            conditional_styles:observable,
            on_change_callback:observable,
            field_in_edition:observable,
            setFieldInEdition:action,
            setDefaultValues: action,
            // validate:action,
            backend_values: computed,
            required_fields: computed,
            set_calculated_value: action,
            clear_values:action,
            set_on_change_callback:action,
            set_groupby_expanded:action,
            groupby_expanded:observable,
            resources_count:observable,
            base_style:observable,
            fields_style:observable,
            set_base_style:action,
            set_resources_count:action,
            
        })

        const id = attributes.id ? attributes.id : group ? group.get_next_new_id() : -1
        this.id = id
        this._values = attributes
        this._changed = {}
        this._values['id'] = id
        this.screen = screen
        this.on_change_callback = attributes.on_change_callback ? attributes.on_change_callback:false
        this.group = group
        this.state_attrs = {}
        this.field_in_edition = false
        this.resources_count = {}
        // group_parent: Parent record on group_by
        this.groupby_childs_domain = this._values['_childs_domain']
        this.grouped_by = this._values['_grouped_by']
        this.groupby_parent = groupby_parent ? groupby_parent:this._values['_groupby_parent']
        this.groupby_expanded = this._values['_groupby_expanded'] || false
        this.set_state_attrs()
        this.set_base_style()
        this.set_conditional_styles()
        this.intialize_formula_fields()
        


    }

    intialize_formula_fields(){
        this.formula_fields.forEach(function(f){
            f.get_value(this)
        }.bind(this))
    }
    set_on_change_callback(f){
        this.on_change_callback = f
    }
    setFieldInEdition(fname){
        this.field_in_edition = fname;
    }

    get_value(fname) {
        if(this.formula_fnames.includes(fname)){
            
            let value = this._values[fname].get_value()
            return value
            
        }
        
        return this._values[fname]
    }

    isField(fname) {
        return this.screen.field_names.includes(fname)
    }

    on_change() {

        let values = {}
        let prms = []
        this.listener_fields.forEach(function (field) {

            if (this._values[field.name]) {
                if (!this._values[field.name].shouldReload(this)) {
                    return false
                }
                else {
                    
                    prms.push(this._values[field.name].reload(this))
                    this._changed[field.name] = true
                    
                }
            }
            else {
                values[field.name] = new CalculatedValue(field, this)
            }


        }.bind(this))

        if (Object.keys(values).length) {
            this.set_values(values)
        }
        return prms

    }
    set_calculated_value(field) {

        if (this._values[field.name]) {
            return this._values[field.name].get_value()
        }
        else {
            this._values[field.name] = new CalculatedValue(field, this)
        }

        return this._values[field.name].get_value()

    }

    set_new_calculated_value(field) {

        if (this._values[field.name] && this._values[field.name] instanceof NewCalculatedValue) {
            return this._values[field.name].get_value()
        }
        else {
            this._values[field.name] = new NewCalculatedValue(field, this)
        }

        return this._values[field.name].get_value()

    }
    set_binary_value(field) {
        if (this._values[field.name] && this._values[field.name] instanceof BinaryValue) {
            return this._values[field.name]
        }
        else {
            this._values[field.name] = new BinaryValue(field, this, this._values[field.name], this._values[field.name])
        }

        return this._values[field.name]
    }

    run_on_change_callbacks(changed_fnames){
        if(this.on_change_callback){
            this.on_change_callback(this, changed_fnames)
        }
        if(this.group){
            this.group.runCallback(this)
        }
        
    }



    set_changed(fnames) {
        let changed = false
        fnames.forEach(function (fname) {
            if (this.isField(fname)) {
                this._changed[fname] = true
                changed = true
            }

        }.bind(this))
        if (changed && this.group && !this.group._changed) {
            this.group.setChanged(changed)
        }
        let prms = this.on_change()
        
        if(prms && prms.length){
            Promise.all(prms).then(function(p){
                this.run_on_change_callbacks(fnames)
            }.bind(this))
        }
        else{
            this.run_on_change_callbacks(fnames)
        }
        
        
        // if (prms.length) {
        //     Promise.all(prms).then(function (p) {

        //         this.set_state_attrs()
        //     }.bind(this))
        // }
        // else {
        //     this.set_state_attrs()
        // }


    }

    set_value(fname, value) {
        this._values[fname] = value
        this.set_changed([fname])

    }
    
    set_values(values, nested=false) {
        const fnames = []
        for (let fname in values) {
            this._values[fname] = values[fname]
            fnames.push(fname)
            if(nested){
                let field = this.group.o2m_fields.find(f => f.name == fname)
                if(field){
                    field.set_value(values[fname], this)
                }
            }

        }
        
        this.set_changed(fnames)

        
    }

    clear_values(){
        this._values = {}
    }

    async extend_values(fnames=[]){
        let prms = []
        fnames.forEach(async function(fname){
            let field = this.screen.get_field_by_name(fname)
            // prms.push(await field.extend_value(this))
            let extended = await field.extend_value(this)
        }.bind(this))
        
        
    }

    /**
* Get default values based on parent_record
* @param {Record} parent - Array of records. If undefined, the current screen selected records will be used
* @return {array} new (saved) parent records or false
*/
    get_parent_defaults(parent_record) {
        let values = {}
        if (!parent_record) {
            if(this.screen.parent.active_record){
                parent_record = this.screen.parent.active_record
            }
            else{
                if(this.screen.parent_selected_records && this.screen.parent_selected_records.length){
                    parent_record = this.screen.parent_selected_records[0]
                }
                
            }
            
        }
        
        if (parent_record) {

            this.parent_default_fields.forEach(function (f) {
                if(f.default_value == '{_parent_record}'){
                    values[f.name] = parent_record._values
                }
                else{
                    values[f.name] = browseObject(parent_record._values, f.default_value, false, this.screen.fields, parent_record._values)
                }
                if(this.keywords.hasOwnProperty(values[f.name])){
                    values[f.name] = parent_record.keywords[values[f.name]]()
                }

            }.bind(this))
            
            
        }
        this.parent_selected_records_fields.forEach(function (f) {
            // values[f.name] = browseObject(parent_record._values, f.default_value, false, this.screen.fields, parent_record._values)
            values[f.name] = this.screen.parent_selected_records
            values[f.name] = browseObject({},f.default_value,false,this.screen.fields,{},this.screen.parent_selected_records)

        }.bind(this))
        return values
    }

    async setDefaultValues(pivot_values=[]) {
        let values = await this.getDefaultValues(pivot_values)
        //REVIEW
        if (values[0].hasOwnProperty('id')) {
            this.id = values[0].id
        }
        values = { ...values[0], ...this.get_parent_defaults() }
        //TODO: Review _parent_record data => this works, but the approach is under revision
        this.field_with_defaults.map(function(field){
            if(!values.hasOwnProperty(field.name)){
                // Remove brackets from default value, to check if is included on record keywords
                const keyword_name = field.default_value.replace('{', "").replace('}', "")
                if(this.keywords.hasOwnProperty(keyword_name)){
                    values[field.name] = this.keywords[keyword_name]()
                }
                else if(keyword_name.includes('_screen_context')){
                    let expr = keyword_name
                    expr = expr.replace(expr.split('.')[0]+'.',"")
                    let value = browseObject(this.screen.context, expr)
                    if(value != expr){
                        values[field.name] = value
                    }
                    
                }

                
            }
        }.bind(this))


        this.set_values(values)
        this.extend_values(this.field_with_defaults.map(function(f){return f.name}))

    }

    async getDefaultValues(pivot_values=[]) {
        let args = { view_id: this.screen.id, search: {}, order: [], pivot_values:pivot_values }
        const abortController = new AbortController();
        let default_values = await this.group.connection.dispatch('GET', '/view/default_values', args, false, false, false, abortController)
        return default_values


    }

    get_values(fnames){
        let vals = {}
        if(!fnames){
            fnames = this.screen.field_names
        }
        fnames.forEach(function(fname){
            if( this._values[fname] && 
                typeof this._values[fname].get_value === 'function' ){
                    vals[fname] = this._values[fname].get_value()    
                }
            else{
                vals[fname] = this._values[fname]
            }
        }.bind(this))
            
        if(!vals['id']){
            vals['id'] = this.id
        }
        
        return vals
    }

    get_changed_values() {

        let cv = {}
        for (let field in this._changed) {
            cv[field] = this._values[field]
        }
        
        let fnames = Object.keys(cv)
        return this.get_values(fnames)

    }

    get_changed_childs_records() {
        let values = {}
        if (!this.group) {
            return {}
        }
        this.group.o2m_fields.filter(function (f) {
            return f.screen && f.screen.data._changed === true
        }).forEach(function (f) {
            values[f.name] = f.screen.data.records
        })

        return values


    }

    get_childs_records() {
        let values = {}
        this.group.o2m_fields.forEach(function (f) {
            values[f.name] = f.screen.data.records
        })

        return values
    }

    validate(required_fields) {
        

        let valid = true
        let _errors = {}
        if (!required_fields) {
            required_fields = this.required_fields
        }
        
        required_fields.forEach(function (field) {
            if (!field.has_value(this)) {
                valid = false
                _errors[field.name] = "required"
            }
        }.bind(this))
        this._errors = _errors;
        
        if (valid) {
            valid = this.validate_childs()
        }
        if (!valid) {
            this.screen.notifications.addSnack(
                { message: "Por favor, complete los campos requeridos.", level: 'error', timeout: 2000 }
            )
        }
        return valid
    }
    validate_childs() {
        let valid = true
        let child_values = this.get_changed_childs_records()
        for (let child in child_values) {
            child_values[child].forEach(function (rec) {
                if (!rec.validate()) {
                    valid = false
                }
            })
        }
        return valid
    }
    //All values except calculated
    get_raw_values({fnames=false, only_template_values=false}){
        let raw_values = {}
        let calculated_fnames = [...this.listener_fields.map(function(f){return f.name}), ...this.formula_fields.map(function(f){return f.name})
        ]
        // template values with no fnames provided: return only template fnames
        if(!fnames && only_template_values){
            fnames =  this.screen.template_fields.map(function(f){return f.name})
        }
        // if fnames provided and only template, return the template fnames included in fnames arg
        else if(fnames && only_template_values){
            fnames = this.screen.template_fields.filter(function(f) {return fnames.includes(f.name)})
        }
        // Default: use al fnames on screen
        if(!fnames){
            fnames = this.screen.field_names   
        }
        
        for(let k in this._values){
            if(!calculated_fnames.includes(k) && fnames.includes(k)){
                raw_values[k] = this._values[k]
            }
        }
        let child_values = this.get_childs_records()

        for (let child in child_values) {
            if(fnames.includes(child)){
                raw_values[child] = child_values[child].map(function (c) {
                    return c.get_raw_values({only_template_values:only_template_values})
                })
            }
            
        }
        return raw_values

    }
    get_template_values(){
        // let fnames = this.template_fields.map(function(f){return f.name})
        let values = this.get_raw_values({only_template_values:true})
        return values
    }
    get_all_values() {
        const fnames = Object.keys(this._values)
        let values = this.get_values(fnames)
        
        let child_values = this.get_childs_records()
        for (let child in child_values) {
            values[child] = child_values[child].map(function (c) {
                return c.get_all_values()
            })
        }
        return values;
    }
    // get_all_values() {
    //     let values = { ...this._values }
    //     let fnames = Object.keys(values)
        
    //     //solve listener fields values
    //     this.listener_fields.forEach(function (lf) {
            
    //         if (fnames.includes(lf.name)) {
                
    //             values[lf.name] = this._values[lf.name].get_value()
                
    //         }
    //         else{
                
    //         }
    //     }.bind(this))

    //     this.binary_fields.forEach(function (bf) {
    //         if (fnames.includes(bf.name)) {
    //             if(this._values[bf.name] && this._values[bf.name].hasOwnProperty('get_value')){
    //                 values[bf.name] = this._values[bf.name].get_value()
    //             }
    //             else{
    //                 values[bf.name] = null
    //             }
                
    //         }
    //     }.bind(this))

    //     this.formula_fields.forEach(function (ff) {
    //         if (fnames.includes(ff.name)) {
    //             values[ff.name] = this._values[ff.name].get_value()
    //         }
    //     }.bind(this))

    //     let child_values = this.get_childs_records()
    //     for (let child in child_values) {
    //         values[child] = child_values[child].map(function (c) {
    //             return c.get_all_values()
    //         })
    //     }
    //     if(!values['id']){
    //         values['id'] = this.id
    //     }
    //     return values;
    // }
    async save(action) {

        if (!this.validate()) {
            return false
        }
        const abortController = new AbortController();
        if(!action){
            action = this.screen.default_save_action
        }
        const path = action.custom_path ? action.custom_path:'/data/save'
        let args = {}
        args['action_id'] = action.id
        let values = {}
        // get every changed field in the record
        let vals = this.get_changed_values()
        // get every record on childs (o2m fields)
        //TODO: Improve to send only changed lines, requires backend changes to accept
        //modifiers
        let child_values = this.get_changed_childs_records()


        for (let child in child_values) {

            vals[child] = child_values[child].map(function (c) {
                return c.get_values()
            })


        }

        // Always add id to the values
        vals.id = this.id

        values[this.id] = vals

        args['values'] = values
        args['model'] = this.screen.model
        if(this.screen.modal && this.screen.parent){
            args['_parent'] = {
                'view_id':this.screen.parent.id,
                'active_record': this.screen.parent.active_record ? this.screen.parent.active_record.id:false
            }
        }

        let res = await this.screen.connection.dispatch('POST', path, args, false, false, false, abortController)
        return res
    }

    _set_state_attrs(state_attrs){
        runInAction(() => {
        
            this.state_attrs = state_attrs;

        })
    }
    
    async set_state_attrs() {
        
        if (!this._values) {
            return false
        }

        // const state_attrs = await this.get_state_attrs()
        const state_attrs = {}
        
        this.screen.fields.forEach(function(field){
            state_attrs[field.name] = new StateAttrs(field, this)
        }.bind(this))
        
        
       this._set_state_attrs(state_attrs)

    }

    set_base_style(style){
        if(style){
            this.base_style = style
            return
        }
        
        const style_expression = this.screen.record_style
        if(style_expression){
            let base_style = {}
            base_style = browseObject(this._values, style_expression)
            if(base_style){
                try{
                    this.base_style = JSON.parse(base_style)
                    let fields_style = this._values['_styles'] || {}
                    if(this.base_style._fields){
                        fields_style = {...this.base_style._fields, ...fields_style}
                    }
                    this.fields_style = fields_style

                    

                }
                catch(e){
                    this.base_style = {}
                }
                
            }
        }
        

        return this.base_style
        

    }

    get_style(fname){

        let style = this.base_style
        let conditional_style = {}
        if(this.conditional_styles && this.conditional_styles[fname]){
            conditional_style = this.conditional_styles[fname].get_value()
        }
        
        if(this.fields_style && this.fields_style[fname]){
            
            style = {...style || {}, ...this.fields_style[fname],  ...conditional_style}
                   
        }
        else{
            style = {...style, ...conditional_style}
        }
        return style

    }

    async set_conditional_styles() {
        
        if (!this._values) {
            return false
        }

        // const state_attrs = await this.get_state_attrs()
        const conditional_styles = {}
        
        this.screen.conditional_style_fields.forEach(function(field){
            conditional_styles[field.name] = new NewCalculatedValue(field,this,field.conditional_style_dependencies,async (calc)=>{return await field.get_conditional_style(calc)})
            
        }.bind(this))

        
        runInAction(() => {
        
            this.conditional_styles = conditional_styles;

        })
        
       

    }

    // GROUP BY Methods

    set_groupby_expanded(value){
        if(!value){
            value = !this.groupby_expanded
        }

        if(value){
            this.loadChilds()
        }
        else{
            this.removeChilds()
        }
        this.groupby_expanded = value
        
    }

    get_group_by_domain(){
        let domain = this.groupby_childs_domain
        if(!domain){
            return []
        }

        if(this.groupby_parent){
            const group_by_parent_record = this.group.get_record_by_id(this.groupby_parent)
            if(group_by_parent_record.groupby_childs_domain){
                domain = [...domain, ...group_by_parent_record.get_group_by_domain()]
            }
            
            
        }
        return domain

    }

    async loadChilds(){
        const domain = this.get_group_by_domain()
        if(!domain.length){
            return
        }
        let rowIndex = this.group.records.indexOf(this) + 1

        let extra_args = {
            search: [...this.screen.current_search, ...domain],
            // group_by:[],
            current_grouping:this.grouped_by,
            partial:true
        }

        let recs = await this.group.loadData(false, false, extra_args)
        // let recs = await this.data.do_search(domain, false, {'partial':true})
        recs.forEach(function(rec){

            this.group.addRecord({values:rec, index:rowIndex, group_parent:this.id})
            rowIndex+=1
        }.bind(this))


    }

    removeChilds(){
        this.groupby_childs.forEach(function(record){
            record.removeChilds()
        })
        this.group.removeRecords(this.groupby_childs)
        
    }

    set_resources_count(value){
        this.resources_count = value 
    }

    async get_resources_count(){
        const value = await this.screen.attachment_handler.resources_count(this.id)
        this.set_resources_count(value)
        
    }






}


