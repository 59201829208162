import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite'
import SessionContext from '../../connection/SessionContext'
import {ScreenGroup} from '../../screen/ScreenGroup'
import ContextMenuContext from './ContextMenuHandler';
import { ContextMenuHandler } from './ContextMenuHandler';

export const ContextMenuProvider = observer(function ContextMenuProvider({children}) {

  const value = new ContextMenuHandler()

    return (
      <ContextMenuContext.Provider value={value}>
        {children}
      </ContextMenuContext.Provider>
    );

    
  })
  
